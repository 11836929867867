import { gql } from '@apollo/client';

export const USER_PRIVATE_FIELDS_FRAGMENT = gql`
  fragment UserPrivateFields on User {
    privyId
    analyticsId
    deactivated
    email
    emailVerified
    hasSeenOnboardingModal
  }
`;
