import { gql } from '@apollo/client';

export const BASE_IMAGE_FRAGMENT = gql`
  fragment BaseImageFields on CloudinaryImage_File {
    id
    publicUrl
    publicUrlTransformed
    _meta {
      public_id
      height
      width
    }
  }
`;
