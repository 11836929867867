import { gql } from '@apollo/client';

export const getUserByPrivyIdQuery = gql`
  query getUserByPrivyId($privyId: String!) {
    user(where: { privyId: $privyId }) {
      __typename
      ...AuthenticatedItemFields
    }
  }
`;
