import { gql } from '@apollo/client';

export const AUTHENTICATED_ITEM_FIELDS_FRAGMENT = gql`
  fragment AuthenticatedItemFields on User {
    # InMemoryCache.fragments
    ...UserPublicFields
    ...UserActiveMemberships
    ...UserPrivateFields
    ...UserPublishingSettings
  }
`;
