import { gql } from '@apollo/client';

export const USER_PUBLIC_FIELDS_FRAGMENT = gql`
  fragment UserPublicFields on User {
    id
    publicAddress
    username
    name
    isConnectedWithLens
    cumulativeTimePoints
    twitterHandle
    bio
    bioLink
    profileImage {
      ...BaseImageFields
    }
  }
`;
