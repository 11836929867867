import { InMemoryCache } from '@apollo/client/cache';
import find from 'lodash/find';
import { skipLimitPaginationForSortedArticles } from './services/skipLimitPaginationForSortedArticles';
import { fragments } from './fragments/createFragmentRegistry';

function skipLimitPagination(keyArgs?: string[]) {
  return {
    keyArgs: keyArgs || (false as false),
    merge(existing: any[], incoming: any[], { args }: any) {
      const merged = existing ? existing.slice(0) : [];

      if (!args) {
        throw new Error(`No args provided to paginated field, keyArgs: ${keyArgs?.join(', ')}`);
      }

      const { skip = 0 } = args;
      if (!skip) {
        return incoming;
      }

      for (let i = 0; i < incoming.length; i += 1) {
        merged[skip + i] = incoming[i];
      }

      return merged;
    },
  };
}

export const cacheFactory = () =>
  new InMemoryCache({
    fragments,
    typePolicies: {
      Query: {
        fields: {
          article: {
            read(_, { args, toReference /* , ...rest */ }) {
              return toReference({
                __typename: 'Article',
                id: args?.where.id,
              });
            },
          },
          territory: {
            read(_, { args, toReference /* , ...rest */ }) {
              return toReference({
                __typename: 'Territory',
                id: args?.where.id,
              });
            },
          },
          user: {
            read(current, { args, toReference, cache /* , ...rest */ }) {
              if (current) {
                return current;
              }
              if (args?.where?.id) {
                return toReference({
                  __typename: 'User',
                  id: args.where.id,
                });
              }
              if (args?.where?.publicAddress || args?.where?.username || args?.where?.privyId) {
                const id = find(cache?.data?.data, args.where)?.id;
                return (
                  id &&
                  toReference({
                    __typename: 'User',
                    id,
                  })
                );
              }
            },
          },
          notifications: skipLimitPagination(['where', 'orderBy']),
          role: {
            read(_, { args, toReference }) {
              if (args?.where?.id) {
                return toReference({
                  __typename: 'Role',
                  id: args.where.id,
                });
              }
            },
          },
          getSortedArticles: {
            keyArgs: (_, { variables }) => {
              const { infiniteScroll } = variables;
              return infiniteScroll ? ['where', 'sortBy'] : ['where', 'sortBy', 'skip', 'take'];
            },
            merge: (...args) => {
              // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
              const [_existing, incoming, context] = args;
              const { infiniteScroll } = context.variables;
              return infiniteScroll ? skipLimitPaginationForSortedArticles().merge(...args) : incoming;
            },
          },
          users: skipLimitPagination(['where', 'orderBy']),
        },
      },
      Territory: {
        fields: {
          articles: skipLimitPagination(['where', 'orderBy']),
        },
      },
      User: {
        fields: {
          articles: skipLimitPagination(['where', 'orderBy']),
          relatedArticles: skipLimitPagination(['where', 'orderBy']),
          collects: skipLimitPagination(['where', 'orderBy']),
        },
      },
      Article: {
        fields: {
          childArticles: skipLimitPagination(['where', 'orderBy']),
        },
      },
    },
  });
