import { gql } from '@apollo/client';

export const USER_ACTIVE_MEMBERSHIP_FRAGMENT = gql`
  fragment UserActiveMemberships on User {
    memberships(where: { status: { equals: ACTIVE } }) {
      id
      status
      role {
        id
        scope
        territory {
          id
        }
        permissions {
          id
          name
          scope
        }
      }
    }
  }
`;
