import { gql } from '@apollo/client';

export const USER_NOTIFICATION_SETTINGS_FRAGMENT = gql`
  fragment UserNotificationSettings on User {
    settingsNotificationNewComment
    settingsNotificationPostCollect
    settingsNotificationReplyToComment
    settingsNotificationReplyToPrompt
    settingsNotificationReplyToQuote
  }
`;
