import { makeVar, OperationVariables, QueryResult, useReactiveVar } from '@apollo/client';
import { User as TUser } from 't2-graphql-types';
import { useEffect, useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import { usePrivy } from 't2-keystone/packages/privyAuthentication/src/hooks/usePrivy';
import { getUserByPrivyIdQuery } from '../queries/getUserByPrivyId';
import { useT2Query } from '../../../hooks/useT2Query';

export type TUserResponse = { user: TUser };

type TUseAuthenticatedUserResult = Partial<QueryResult<TUserResponse, OperationVariables>> & {
  isAuthenticated: boolean;
  authenticatedUserId: string | undefined;
  loading: boolean;
};

export const reactiveUseAuthenticatedUserResult = makeVar<TUseAuthenticatedUserResult>({
  isAuthenticated: false,
  authenticatedUserId: undefined,
  loading: true,
});

const getUseAuthenticatedUserResult = (
  queryResult: QueryResult<TUserResponse, OperationVariables>,
  privyReady: boolean,
): TUseAuthenticatedUserResult => {
  const authenticatedUser = queryResult.data?.user;
  return {
    ...queryResult,
    loading: queryResult.loading || !privyReady,
    isAuthenticated: !!authenticatedUser?.id,
    authenticatedUserId: authenticatedUser?.id,
  };
};

const isUseAuthenticatedUserResultEqual = (a: TUseAuthenticatedUserResult, b: TUseAuthenticatedUserResult) =>
  a?.loading === b?.loading && isEqual(a?.data?.user, b?.data?.user);

export const useAuthenticatedUser = (): TUseAuthenticatedUserResult => {
  const { ready: privyReady, user: privyUser } = usePrivy();
  const queryResult = useT2Query<TUserResponse>(getUserByPrivyIdQuery, {
    variables: { privyId: privyUser?.id },
    skip: !privyReady || !privyUser?.id,
  });
  const useAuthenticatedUserResult = useMemo(
    () => getUseAuthenticatedUserResult(queryResult, privyReady),
    [queryResult.loading, queryResult.error, queryResult.data?.user, privyReady],
  );

  useEffect(() => {
    if (!privyReady || queryResult.loading) {
      return;
    }

    const hasUseAuthenticatedUserResultChanged = !isUseAuthenticatedUserResultEqual(
      // @ts-ignore
      reactiveUseAuthenticatedUserResult(),
      useAuthenticatedUserResult,
    );
    if (!hasUseAuthenticatedUserResultChanged) {
      return;
    }

    reactiveUseAuthenticatedUserResult(useAuthenticatedUserResult);
  }, [useAuthenticatedUserResult]);

  return getUseAuthenticatedUserResult(queryResult, privyReady);
};

export const useReactiveAuthenticatedUser = () => useReactiveVar(reactiveUseAuthenticatedUserResult);
